// Generated by Framer (f1edf09)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useActiveVariantCallback, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const cycleOrder = ["jwDjPQ2fs", "JNv2jaZm5"];

const variantClassNames = {JNv2jaZm5: "framer-v-1kski2q", jwDjPQ2fs: "framer-v-q9k5zq"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {Burger: "jwDjPQ2fs", X: "JNv2jaZm5"};

const transitions = {default: {damping: 30, delay: 0, duration: 0.1, ease: [0, 0, 1, 1], mass: 1, stiffness: 400, type: "tween"}};

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; tap?: any; color?: string }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "jwDjPQ2fs", tap: TBgHLr7MW, color: iWF8EWIN1 = "rgb(136, 136, 136)", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "jwDjPQ2fs", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTapavbb64 = activeVariantCallback(async (...args) => {
if (TBgHLr7MW) {
const res = await TBgHLr7MW(...args);
if (res === false) return false;
}
setVariant("JNv2jaZm5")
})

const onTapmcrbm9 = activeVariantCallback(async (...args) => {
if (TBgHLr7MW) {
const res = await TBgHLr7MW(...args);
if (res === false) return false;
}
setVariant("jwDjPQ2fs")
})

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-SiQC0", classNames)} style={{display: "contents"}}>
<motion.div {...restProps} className={cx("framer-q9k5zq", className)} data-framer-name={"Burger"} data-highlight layoutDependency={layoutDependency} layoutId={"jwDjPQ2fs"} onTap={onTapavbb64} ref={ref} style={{...style}} transition={transition} {...addPropertyOverrides({JNv2jaZm5: {"data-framer-name": "X", onTap: onTapmcrbm9}}, baseVariant, gestureVariant)}><motion.div className={"framer-17ckqdl"} layoutDependency={layoutDependency} layoutId={"aqkJ5kTED"} transition={transition}><motion.div className={"framer-goz0ix"} data-framer-name={"Bottom"} layoutDependency={layoutDependency} layoutId={"krMYvZJj5"} style={{backgroundColor: iWF8EWIN1, rotate: 0}} transition={transition} variants={{JNv2jaZm5: {rotate: -45}}}/><motion.div className={"framer-yjbvdu"} data-framer-name={"Mid"} layoutDependency={layoutDependency} layoutId={"bGfBQgeRR"} style={{backgroundColor: iWF8EWIN1, opacity: 1}} transition={transition} variants={{JNv2jaZm5: {opacity: 0}}}/><motion.div className={"framer-effa62"} data-framer-name={"Top"} layoutDependency={layoutDependency} layoutId={"kBVyNzmKA"} style={{backgroundColor: iWF8EWIN1, rotate: 0}} transition={transition} variants={{JNv2jaZm5: {rotate: 45}}}/></motion.div></motion.div>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-SiQC0 [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-SiQC0 * { box-sizing: border-box; }", ".framer-SiQC0 .framer-17pvdk2 { display: block; }", ".framer-SiQC0 .framer-q9k5zq { cursor: pointer; height: 32px; overflow: hidden; position: relative; width: 32px; }", ".framer-SiQC0 .framer-17ckqdl { flex: none; height: 18px; left: calc(50.00000000000002% - 24px / 2); overflow: visible; position: absolute; top: calc(50.00000000000002% - 18px / 2); width: 24px; }", ".framer-SiQC0 .framer-goz0ix { bottom: 0px; flex: none; height: 2px; left: 0px; overflow: hidden; position: absolute; right: 0px; }", ".framer-SiQC0 .framer-yjbvdu { flex: none; height: 2px; left: 0px; overflow: hidden; position: absolute; right: 0px; top: calc(50.00000000000002% - 2px / 2); }", ".framer-SiQC0 .framer-effa62 { flex: none; height: 2px; left: 0px; overflow: hidden; position: absolute; right: 0px; top: 0px; }", ".framer-SiQC0.framer-v-1kski2q .framer-goz0ix { bottom: unset; top: calc(50.00000000000002% - 2px / 2); }", ".framer-SiQC0.framer-v-1kski2q .framer-yjbvdu { left: unset; width: 2px; }", ".framer-SiQC0.framer-v-1kski2q .framer-effa62 { left: unset; right: -1px; top: calc(50.00000000000002% - 2px / 2); width: 26px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 32
 * @framerIntrinsicWidth 32
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"JNv2jaZm5":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"TBgHLr7MW":"tap","iWF8EWIN1":"color"}
 */
const FramerlFB2U4jwi: React.ComponentType<Props> = withCSS(Component, css, "framer-SiQC0") as typeof Component;
export default FramerlFB2U4jwi;

FramerlFB2U4jwi.displayName = "Elements/Menu Icon";

FramerlFB2U4jwi.defaultProps = {height: 32, width: 32};

addPropertyControls(FramerlFB2U4jwi, {variant: {options: ["jwDjPQ2fs", "JNv2jaZm5"], optionTitles: ["Burger", "X"], title: "Variant", type: ControlType.Enum}, TBgHLr7MW: {title: "Tap", type: ControlType.EventHandler}, iWF8EWIN1: {defaultValue: "rgb(136, 136, 136)", title: "Color", type: ControlType.Color}} as any)

addFonts(FramerlFB2U4jwi, [])